// import firebase from './firebase';
import { objectSerialize, generateUniqueId } from '../utils/Utils';
const host = process.env.REACT_APP_SERVER_URL;

class Api {

    constructor() {}

    refreshTokenFunction =  () => {};
    signOutFunction = () => {};

    setRefreshTokenFunction = (refreshTokenFunction) => {
        this.refreshTokenFunction = refreshTokenFunction;
    }

    setSignOutFunction = (signOutFunction) => {
        this.signOutFunction = signOutFunction;
    }

    setErrorFunction = (errorFunction) => {
        this.errorFunction = errorFunction;
    }

    token = window.localStorage.getItem('token');
    tokenDate = window.localStorage.getItem('tokenDate');
    refreshToken = window.localStorage.getItem('refreshToken');
    errorFunction = () => {}

    setToken = (token) => {
        this.token = token;
        this.tokenDate = new Date();
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('tokenDate', this.tokenDate);
    }

    setRefreshToken = (refreshToken) => {
        this.refreshToken = refreshToken;
        window.localStorage.setItem('refreshToken', refreshToken);
    }

    headers(token) {
        return {
            'Content-Type': 'application/json',
            'Authorization' : token ? 'Bearer '+token : '',
        }
    }

    get(route, query, callback, callbackErr) {
        return this.xhr(route, null, query, callback, 'GET', true, callbackErr);
    }

    put(route, query, callback, callbackErr) {
        return this.xhr(route, null, query, callback, 'PUT', true, callbackErr);
    }

    post(route, params, query, callback, callbackErr) {
        return this.xhr(route, params, query, callback, 'POST', true, callbackErr);
    }

    delete(route, query, callback) {
        return this.xhr(route, null, query, callback, 'DELETE', true);
    }

    xhr(route, params, query, callback, verb, canRefreshToken, callbackErr) {
        const token = this.token;
        const queryString = query ? '?'+objectSerialize(query) : '';
        const url = `${host}/api/${route}${queryString}`;

        let options = Object.assign({ method: verb }, params ? { body: JSON.stringify(params) } : {});
        options.headers = this.headers(token);
        const self = this;


        if (canRefreshToken && url !== host+'/api/login_check' && (!this.tokenDate || !this.token || ((new Date() - new Date(this.tokenDate)) / 3600000) > 1)) {
            self.getNewTokenAndRefreshToken(() => {
                self.xhr(route, params, query, callback, verb, false);
            }, () => {
                self.setToken('');
                self.setRefreshToken('');
            });

            return;
        }

        fetch(url,options)
            .then(response => {
                if (response.status === 200 && typeof callback !== 'undefined') {
                    return response.json();
                } else if (response.status === 401) {
                    if (url === host+'/api/login_check') {
                        throw 'Email et/ou mot de passe incorrect.';
                    }

                    throw 'NO_AUTHORIZE';
                }

                if (response.status !== 200) {
                    throw 'ERROR DATABASE - ' + response.status + ' - URL : ' + url;
                }
            }).then((response) => {
                callback(response);
            }).catch((err)=> {
                if (typeof callbackErr === 'function') {
                    callbackErr(err);
                }

                if (err === 'NO_AUTHORIZE') {
                    if (!canRefreshToken) {
                        return;
                    }

                    self.getNewTokenAndRefreshToken(() => {
                        self.xhr(route, params, query, callback, verb, false);
                    }, () => {
                        self.setToken('');
                        self.setRefreshToken('');
                    });

                    return;
                }

                if (url !== host+'/api/login_check') {
                    self.errorFunction(generateUniqueId(), url + ' => ' + (typeof err === 'object' ? JSON.stringify(err) : err));
                }
                return;
            });

    }

    getNewTokenAndRefreshToken(fetchFunction, callbackErr) {
        console.log("getNewTokenAndRefreshToken")
        const self = this;

        var data = new FormData();
        data.append('refresh_token', this.refreshToken);

        fetch(`${host}/token/refresh`, {
            method: 'POST',
            body: data,
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }

            throw 'STATUS : '+response.status;
        })
        .then(response => {
            self.setRefreshTokenFunction(response.token, response.refresh_token);
            self.setToken(response.token);
            self.setRefreshToken(response.refresh_token);
            fetchFunction();
        }).catch((err)=>{
            if (typeof callbackErr === 'function') {
                callbackErr();
            }
            self.signOutFunction();
        });
    }

}
export default Api
