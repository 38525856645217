import { setBooks } from "../slices/booksSlice";
import { setShelves } from "../slices/shelvesSlice";
import { addAuthorSubcription, addSeriesSubcription, removeAuthorSubcription, removeSeriesSubcription, setSubscriptions } from "../slices/subscriptionsSlice";
import { setTag, setTags } from "../slices/tagsSlice";
import { setErrorMessage, setSystemMessage, setUser } from "../slices/userSlice";
import Api from "./api";
import BooknodeApi from "./booknodeApi";
import { AppDispatch, AppThunk, store } from "./store";
export const BOOKNODE_URL = 'https://booknode.com';
export enum LOGGER_GROUPS {
    FETCH_API = 'FETCH_API'
}

export const LOGGER_URL = process.env.REACT_APP_LOGGER_URL;
export const APPLICATION_LOGGER_ID = 2;

export const api = new Api();
api.setErrorFunction((id: string, err: string) => {
    sendError();
    insertInLogService('error', LOGGER_GROUPS.FETCH_API, err);
})

api.setSignOutFunction(() => {
    fetchLogout();
    window.location.href = '/connection';
})

const insertInLogService = (type: 'error' | 'info' | 'debug', group: LOGGER_GROUPS, messages: string | string[]) => {
    fetch(`${LOGGER_URL}/addLog/${APPLICATION_LOGGER_ID}/${type}`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ group, messages })
    }).catch(err => console.error(err));
}

const sendError = () => {
    removeSystemMessage(store.dispatch);
    store.dispatch(setErrorMessage('Une erreur est survenue'));
    setTimeout(() => {
        store.dispatch(setErrorMessage(null));
    }, 3000);
}

export const checkSession = () => {
    api.get('users/checkSession', null, () => { });
}

export const fetchConnection = (username: string, password: string, callback: Function, errCallback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.post('login_check', { username, password }, null, (result: any) => {
        api.setToken(result.token);
        api.setRefreshToken(result.refresh_token)
        isFirstConnection(callback);
        removeSystemMessage(dispatch);
    }, (msg: string) => {
        errCallback(msg);
        removeSystemMessage(dispatch);

    });

}

export const isFirstConnection = (callback: Function) => {
    api.get('users/isFirstConnection', null, (result: any) => {
        callback(result);
    })
}

export const fetchInit = (callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('init', null, (result: any) => {
        dispatch(setShelves(result.shelves));
        dispatch(setBooks(result.booksComponentByShelf));
        dispatch(setTags(result.tags));
        dispatch(setUser(result.user));
        callback(result.nbNews)
        removeSystemMessage(dispatch);
    });
}

export const fetchUser = (): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);

    api.get('users/info', null, (result: any) => {
        dispatch(setUser(result));
        removeSystemMessage(dispatch);
    });
}

export const fetchCreateUser = (email: string, password: string, callback: Function, callbackErr: Function): AppThunk => (dispatch, getState) => {
    sendPendingSaveMessage(dispatch);
    api.put('users/create', { email, password }, (result: any) => {
        if (result && result.error) {
            callbackErr(result.error);
            return;
        }
        callback(result);
        removeSystemMessage(dispatch);
    });
}

export const fetchShelves = (): AppThunk => (dispatch, getState) => {
    api.get('shelves', null, (result: any) => {
        dispatch(setShelves(result.shelves));
    });
}

export const fetchUpdateShelves = (shelves: any[], callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);

    api.post('shelves/update', { shelves }, null, (result: any) => {
        dispatch(setShelves(result.shelves));
        removeSystemMessage(dispatch);
    });
}

export const fetchTags = (): AppThunk => (dispatch, getState) => {
    api.get('tags', null, (result: any) => {
        dispatch(setTags(result.tags));
    });
}

export const fetchBooksDetail = (booksId: number[], withAuthorSubscription: boolean = false, callback: Function) => {
    api.get('booksById', { booksId, withAuthorSubscription }, (result: any) => {
        callback(result.books, result.hasAuthorSubscription);
    });
}
export const fetchInternetBook = (url: string, website: string, callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('books/show', { url, website }, (result: any) => {
        callback(result.book, result.database);
        dispatch(fetchHistoryAdd(result.book.bookUrl, 'FORM_BOOK', result.book.name));
        removeSystemMessage(dispatch);
    });
}

export const fetchCheckUpdate = (book: any): AppThunk => (dispatch, getState) => {
    api.post('books/checkUpdate', { book }, null, (result: any) => {
        if (result && result.hasUpdate) {
            dispatch(setSystemMessage('Une mise à jour a été effectuée.'));
            setTimeout(() => {
                dispatch(setSystemMessage(null));
            }, 3000);
        }
    }, () => { });
}


export const fetchHomeBooks = (): AppThunk => (dispatch, getState) => {
    api.get('books', null, (result: any) => {
        dispatch(setBooks(result.booksComponentByShelf));
    });
}

export const fetchChangeShelf = (shelfId: number, booksId: number[], callback: Function): AppThunk => (dispatch, getState) => {
    sendPendingSaveMessage(dispatch);
    api.get('books/changeShelf', { shelfId, booksId }, () => {
        callback();
        removeSystemMessage(dispatch);
    });

}


export const fetchChangeComment = (bookId: number, comment: string, callback: Function): AppThunk => (dispatch, getState) => {
    sendPendingSaveMessage(dispatch);
    api.post('books/update/comment', { bookId, comment }, null, () => {
        callback();
        removeSystemMessage(dispatch);
    });

}

export const fetchChangeTags = (bookId: number, tags: number[], callback: Function): AppThunk => (dispatch, getState) => {
    sendPendingSaveMessage(dispatch);
    api.post('books/changeTags', { bookId, tags }, null, () => {
        callback();
        removeSystemMessage(dispatch);
    });

}

export const fetchDeleteBook = (bookId: number): AppThunk => (dispatch, getState) => {
    sendPendingSaveMessage(dispatch);
    api.delete('books/remove', { bookId }, () => {
        dispatch(fetchHomeBooks());
        removeSystemMessage(dispatch);
    });

}

export const fetchAddTag = (tagName: string): AppThunk => (dispatch, getState) => {
    sendPendingSaveMessage(dispatch);
    api.post('tags/add', { name: tagName }, null, (result: any) => {
        dispatch(setTags(result.tags));
        removeSystemMessage(dispatch);
    });
}

export const fetchUpdateTag = (tagId: number, tagName: string): AppThunk => (dispatch, getState) => {
    sendPendingSaveMessage(dispatch);
    api.post('tags/update', { id: tagId, name: tagName }, null, (result: any) => {
        dispatch(setTag({ id: tagId, name: tagName }));
        removeSystemMessage(dispatch);
    });
}

export const fetchSearch = (search: string, website: string, callback: Function, dispatch: any) => {
    sendLoadingMessage(dispatch);
    const api = new BooknodeApi();
    api.search(search, (result: any) => {
        removeSystemMessage(dispatch);
        callback(result);
    });
}

export const fetchAuthor = (url: string, website: string, callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('author', { url, website }, (result: any) => {
        callback(result);
        dispatch(fetchHistoryAdd(result.url, 'FORM_AUTHOR', result.name));
        removeSystemMessage(dispatch);
    });
}

export const fetchGetSubscriptions = (): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('users/follow/show', {}, (result: any) => {
        dispatch(setSubscriptions(result));
        removeSystemMessage(dispatch);
    });
}

export const fetchUnFollow = (url: string, type: 'series' | 'author', callback: Function | undefined = undefined): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);

    api.post(type === 'series' ? 'users/followSeries/remove' : 'users/followAuthor/remove', { [type === 'series' ? 'seriesUrl' : 'authorUrl']: url }, null, () => {
        if (type === 'series') {
            dispatch(removeSeriesSubcription(url));
        } else {
            dispatch(removeAuthorSubcription(url));
        }

        if (typeof callback === 'function') {
            callback();
        }
        removeSystemMessage(dispatch);
    });
}
export const fetchSeriesFollow = (seriesUrl: string, website: string, seriesName: string, booksUrl: string[], callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);

    api.post('users/followSeries/add', { seriesUrl, website, seriesName, books: booksUrl }, null, () => {
        callback();
        removeSystemMessage(dispatch);
    });
}

export const fetchAuthorFollow = (authorUrl: string, website: string, authorName: string, booksUrl: string[], callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);

    api.post('users/followAuthor/add', { authorUrl, website, authorName, books: booksUrl }, null, () => {
        dispatch(addAuthorSubcription({ url: authorUrl, name: authorName }));
        callback();
        removeSystemMessage(dispatch);
    });
}

export const fetchFirstBookUrlOnSeries = (url: string, website: string, callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('books/series/firstBookUrl', { url, website }, (result: any) => {
        callback(result.bookUrl);
        removeSystemMessage(dispatch);
    });
}

export const fetchAddBooks = (books: any[], shelfId: number, callback: Function) => {
    sendPendingSaveMessage(store.dispatch)
    api.post('books/add', { books, shelfId }, null, () => {
        removeSystemMessage(store.dispatch);
        callback();
    });
}

export const fetchRemoveBook = (bookId: number, callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);

    api.delete('books/remove', { bookId }, (result: any) => {
        callback(result);
        removeSystemMessage(dispatch);
    });
}

export const fetchNotifications = (callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('notifications', {}, (result: any) => {
        callback(result);
        removeSystemMessage(dispatch);
    });
}

export const fetchNotificationRemove = (notifId: number | 'ALL', callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.post('notifications/delete', { notifId }, null, () => {
        callback();
        removeSystemMessage(dispatch);
    });
}

export const setNotificationOld = (notifsId: number[]) => {

    api.post('notifications/old', { notifsId }, null, () => { });
}

export const fetchHistory = (callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('history', {}, (result: any) => {
        callback(result);
        removeSystemMessage(dispatch);
    });
}

export const fetchHistoryRemove = (id: number | 'ALL', callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.post('history/remove', { id }, null, () => {
        callback();
        removeSystemMessage(dispatch);
    });
}

export const fetchHistoryAdd = (link: string, linkType: 'FORM_BOOK' | 'FORM_AUTHOR', content: string): AppThunk => (dispatch, getState) => {

    api.post('history/add', { link, linkType, content }, null, () => { });
}


export const fetchComments = (url: string, page: number, callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('books/comments', { url: url + '?page=' + page }, (response: any) => {
        callback(response.comments, response.pagination);
        removeSystemMessage(dispatch);
    });
}

export const fetchExtracts = (url: string, page: number, callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('books/extracts', { url: url + '?page=' + page }, (response: any) => {
        callback(response.extracts, response.pagination);
        removeSystemMessage(dispatch);
    });
}

export const fetchThemesList = (callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('search/themes', {}, (response: any) => {
        callback(response.themes, response.subThemes);
        removeSystemMessage(dispatch);
    });
}

export const fetchBooksByTheme = (url: string, page: number, callback: Function): AppThunk => (dispatch, getState) => {
    sendLoadingMessage(dispatch);
    api.get('search/themes/books', { url, page }, (response: any) => {
        callback(response.books);
        removeSystemMessage(dispatch);
    });
}

export const fetchCreateNotif = (type: 'APP_UPDATE', description: string, callback: Function): AppThunk => (dispatch) => {
    sendLoadingMessage(dispatch);

    api.post('notifications/create', { type, description }, null, () => {
        callback();
        removeSystemMessage(dispatch);
    })
}

export const fetchLogout = () => {
    api.setToken('');
    api.setRefreshToken('');
    store.dispatch(setShelves([]));
    store.dispatch(setBooks({}));
    store.dispatch(setTags([]));
}

export const fetchChangePassword = (newPassword: string, callback: Function): AppThunk => (dispatch, getState) => {
    api.post('users/changePassword', { new: newPassword }, null, () => callback());
}

export const sendLoadingMessage = (dispatch: any) => {
    dispatch(setSystemMessage('Chargement...'));
}
export const sendPendingSaveMessage = (dispatch: any) => {
    dispatch(setSystemMessage('Enregistrement en cours...'));
}

export const removeSystemMessage = (dispatch: any) => {
    dispatch(setSystemMessage(null));
}


export const fetchStats = (year: string, callback: Function): AppThunk => (dispatch) => {
    sendLoadingMessage(dispatch);
    api.get('stats', { year }, (response: any) => {
        removeSystemMessage(dispatch);
        callback(response);
    });
}

export const updateDateStatsForBook = (date: string, bookId: number): AppThunk => (dispatch) => {
    sendPendingSaveMessage(dispatch);
    api.post('stats/update', { date, bookId }, null, () => {
        dispatch(setSystemMessage('Mise à jour réussie'));
        setTimeout(() => {
            removeSystemMessage(dispatch);
        }, 1000)
    });
}

export const deleteBookStat = (bookId: number, callback: Function): AppThunk => (dispatch) => {
    sendPendingSaveMessage(dispatch);
    api.post('stats/delete', { bookId }, null, () => {
        removeSystemMessage(dispatch);
        callback();
    });
}
